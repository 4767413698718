.loginContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .8; }

.loginBox {
  width: 700px;
  height: auto;
  max-height: 400px;
  margin: auto;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding: 40px; }
  .loginBox a:hover {
    background-color: #fff; }

.registerBox {
  height: auto;
  max-height: 600px; }

.accountBox {
  width: 800px;
  height: auto;
  max-height: 2000px; }

.accountInner {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px; }
