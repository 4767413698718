h1, h2, h3 {
  font-weight: 300; }

/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  resultColor: #29abe0;
  resultColorAlpha: rgba(41, 171, 224, 0.5);
  noResultColor: #555;
  highlightColor: #f39e39;
  selectedColor: #a9c480;
  plotColor: #f39e39;
  brushSelectionColor: #a9c480;
  frequencyColor: #eb744f; }

.filterBar {
  width: 180px;
  float: left;
  text-align: center;
  overflow-y: scroll;
  font-size: 10pt; }

.map_container {
  margin-left: 180px; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.mapApp {
  position: relative; }

.italic-text {
  font-style: italic; }

.center-loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

/* for leaflet-draw */
.sr-only {
  display: none; }

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto; }

.leaflet-touch .leaflet-bar a {
  line-height: 16px; }

.leaflet-control-layers-list {
  padding: 10px;
  text-align: left; }

div.leaflet-bottom.leaflet-right {
  margin-right: 350px; }

/*.leaflet-pane.leaflet-overlay-pane > svg {
  pointer-events: visiblePainted !important;
}

.leaflet-pane.leaflet-overlay-pane > svg > g > path.leaflet-interactive {
  pointer-events: visiblePainted !important;
}*/
.leaflet-overlay-pane svg path {
  pointer-events: auto !important; }

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 31px !important;
  line-height: 22px !important; }

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a:hover {
  color: #fff;
  text-decoration: none; }

button.recenterButton {
  border-radius: 4px;
  border-width: 1px;
  border-color: #bbb;
  background: transparent;
  color: #1a1a1a; }

button.recenterButton:hover {
  color: black;
  /*background: rgba(255,255,255,.65)*/ }

.recenter-div:hover {
  /*background: rgba(255,255,255,.3)*/
  background: #eee; }

.recenter-div {
  margin-right: 350px;
  color: #1a1a1a;
  background: rgba(255, 255, 255, 0.9);
  border-collapse: separate;
  border-radius: 4px;
  border-width: 2px;
  border-color: #aaa;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65); }
