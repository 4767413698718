a {
  font-family: sans-serif;
  color: #DB7365;
  padding: 0.3rem; }

a:hover {
  background-color: #DB7365;
  color: #fff1e0; }

body {
  background: #fff1e0; }

line.tick {
  stroke-width: 0.5; }

line.minor-tick {
  stroke-width: 1;
  stroke-opacity: 0.1; }

.a-axis {
  stroke: #333; }

.b-axis {
  stroke: #333; }

.c-axis {
  stroke: #333; }

.axis-title {
  font-family: sans-serif;
  font-size: 1.5rem; }

text.tick-text {
  font-family: sans-serif;
  font-weight: lighter;
  font-size: 0.8rem;
  fill: #333;
  stroke: none; }

circle.piper.anion {
  /*fill: #fff1e0;
  stroke: #DB7365;*/
  fill: #EED8AE;
  stroke: #FFA812;
  stroke-width: 2px; }

circle.piper.cation {
  /*fill: #fff1e0;
  stroke: #DB7365;*/
  fill: #E0EEEE;
  stroke: #388E8E;
  stroke-width: 2px; }

circle.piper.diamond {
  fill: #E0EEE0;
  stroke: #63AB62;
  stroke-width: 2px; }

circle.piper.tds {
  fill: #99A4AE;
  stroke: #34495E;
  stroke-width: 2px; }

circle.piper.ph {
  fill: #b197bc;
  stroke: #632f79;
  stroke-width: 2px; }

.piper-highlighted-diamond, .piper-highlighted-cation, .piper-highlighted-anion, .piper-highlighted-tds, .piper-highlighted-ph {
  fill: #EEE9E9;
  stroke: #CC3232;
  stroke-width: 3px; }

.d3-tip {
  line-height: 1;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  z-index: 10000; }

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center; }

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0; }

.modified-hovered {
  opacity: 1;
  stroke-width: 3px !important;
  fill: #EEE9E9 !important;
  stroke: #CC3232 !important; }
