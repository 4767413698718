h1, h2, h3 {
  font-weight: 300; }

/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  siteColor: #29abe0; }

.query-border {
  border-top: solid #666666 2px;
  border-bottom: solid #666666 2px;
  border-left: dotted #666666 2px; }

.query-border-left {
  border-top: solid #666666 2px;
  border-bottom: solid #666666 2px;
  border-left: solid #666666 2px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px; }

.query-border-right {
  border-top: solid #666666 2px;
  border-bottom: solid #666666 2px;
  border-right: solid #666666 2px;
  border-left: dotted #666666 2px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; }

.ndep-danger {
  background-color: #eb744f !important;
  border-color: #eb744f !important; }

.fieldContainer {
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left; }

.queryFilters {
  max-width: 100%;
  font-size: 10pt; }

.radioHotSpot {
  padding-left: 5px;
  margin-left: 10px; }

.newQueryFilters {
  /*max-height:637px;*/
  overflow: scroll; }
  .newQueryFilters li {
    list-style-type: none;
    cursor: pointer; }
    .newQueryFilters li .fa {
      width: 20px;
      margin-left: -20px; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.fa-remove:before {
  margin-left: 5px;
  margin-right: 5px; }

li span.fa-remove {
  width: 15px; }

.filterQueryContainer {
  border-bottom: 1px solid #eee;
  padding: 10px 5px 10px 10px;
  background: #3e3f3a;
  color: #eee;
  padding-left: 30px;
  text-align: left;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none; }

.filterQueryContainer > span {
  font-size: 115%;
  width: 10%; }

.filterQueryContainer > span:after {
  display: block; }

.filterQueryContainerInner {
  display: inline-block;
  width: 90%; }

.filterQueryContainerInner > h4 {
  margin: 0;
  font-size: 115%; }

.blue-text {
  color: #29abe0; }

.gray-out {
  color: #b3b3b3; }

.bold-text {
  font-weight: bold; }

.italic-text {
  font-style: italic; }

.filterQueryContainer > span > .fa {
  color: #eee;
  float: right; }

#filterTitle {
  padding: 15px 0;
  margin: 0; }

.dashed-underline {
  border-bottom: 1px dotted #000; }

.newQueryFilters {
  padding-left: 20px; }
  .newQueryFilters .filterList {
    padding: 0;
    margin: 0;
    text-align: left; }
  .newQueryFilters .filterList li {
    list-style-type: none;
    /*padding: 7px 20px;*/
    cursor: pointer;
    margin: 1px 0;
    text-align: left;
    list-style-type: none; }
  .newQueryFilters .filterList li .fa {
    width: 20px;
    padding-right: 10px; }
  .newQueryFilters .filterList li:hover {
    opacity: .8; }
  .newQueryFilters .filterList li .fa-remove:hover {
    opacity: 1.0; }

#availableFilters {
  max-height: 600px; }

.center-loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.top-loader {
  margin: 0 auto; }

.queryPageContainer {
  padding: 20px 0; }

.queryPageContainer .queryBtnContainer {
  /*padding-left:0;*/ }
  .queryPageContainer .queryBtnContainer button {
    float: left;
    width: 100%; }
    .queryPageContainer .queryBtnContainer button .fa {
      padding-right: 10px;
      margin-left: -10px; }
  .queryPageContainer .queryBtnContainer a {
    padding: 0; }

.queryPageContainer .clearBtnContainer {
  padding: 0;
  float: right; }
  .queryPageContainer .clearBtnContainer button {
    float: right;
    width: 100%; }

.selectedQueryInfo .queryLabel {
  text-align: left;
  font-weight: bold; }

.selectedQueryInfo .queryInfo {
  text-align: left; }

.fieldContainer button {
  float: left;
  margin-right: 20px;
  margin-top: 30px; }
  .fieldContainer button .fa {
    padding-left: 10px;
    margin-right: -5px; }

.fa-right {
  margin-left: 5px; }

.fa-left {
  margin-right: 5px; }
