/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  resultColor: #29abe0; }

.filterContainer {
  margin-bottom: 4px; }

#filterTitle {
  padding: 15px 0;
  margin: 0; }

.filterContainer .filterList {
  padding: 0;
  margin: 0; }

.filterContainer .filterList li {
  list-style-type: none;
  padding: 7px 10px 7px 30px;
  cursor: pointer;
  margin: 1px 0;
  text-align: left; }

.filterContainer .filterList li .fa {
  width: 20px;
  margin-left: -20px; }

.filterContainer .filterList li:hover {
  opacity: .8; }

.filterContainer .rc-slider {
  margin: 20px auto; }

.filterContainer .filterSlider {
  height: 300px;
  margin: auto;
  padding-right: 45px; }

.filterContainer .clearBrush {
  padding: 5px;
  background: #eee; }

.filterContainer .filterCheck {
  color: #eee; }

.filterContainer .filterCheck.active {
  color: #333; }

.filterContainer h4 {
  margin: 0;
  border-bottom: 1px solid #eee;
  padding: 10px;
  background: #3e3f3a;
  color: #eee;
  font-size: 115%;
  padding-left: 30px;
  text-align: left;
  cursor: pointer; }
  .filterContainer h4 .icon-right {
    padding-left: 15px;
    float: right;
    margin-right: 10px; }
  .filterContainer h4 .icon-left {
    width: 20px;
    margin-left: -20px;
    float: left; }
