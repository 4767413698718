.box {
  font: 10px sans-serif; }

.box line,
.box rect,
.box circle {
  fill: steelblue;
  stroke: #000;
  stroke-width: 1px; }

.box .center {
  stroke-dasharray: 3,3; }

.box .outlier {
  fill: none;
  stroke: #000; }

.axis {
  font: 12px sans-serif; }

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges; }

.x.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges; }

.logCheckbox {
  padding: 8px 0;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px; }
  .logCheckbox .icon-left {
    padding-right: 10px; }

.disabled,
.disabled > .fa {
  opacity: 0.5;
  cursor: not-allowed; }
