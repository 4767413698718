/*.quantilePlotContainer{
  width:100%;
  height:100%;
}*/
.quantilePath {
  /*stroke:#4166a3;*/
  stroke: #333;
  stroke-width: 0.8;
  fill: none; }

.quanDot {
  fill: #3d98ff;
  opacity: .5; }

.quanDotBuffer {
  opacity: 0; }

.d3-tip {
  line-height: 1;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  z-index: 100000 !important; }

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center; }

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0; }

.logCheckbox {
  padding: 8px 0;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px; }
  .logCheckbox .icon-left {
    padding-right: 10px; }

.disabled,
.disabled > .fa {
  opacity: 0.5;
  cursor: not-allowed; }
