/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  topSpace: 59;
  navHeight: 52; }

#dataTable {
  /*padding:20px 0;*/ }
  #dataTable .react-modal {
    background: #fff; }
  #dataTable .public_fixedDataTable_main:focus {
    outline: none; }
  #dataTable #summaryButton {
    text-align: right; }
    #dataTable #summaryButton button {
      max-width: 200px; }

#dataFilterTitle {
  padding: 15px 0;
  margin: 0; }
