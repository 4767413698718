/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
#overview_content {
  text-align: left;
  padding: 20px;
  margin: auto;
  margin-bottom: 30px;
  height: 94vh; }

#overview_inner {
  max-width: 900px;
  margin: auto; }
  #overview_inner ul, #overview_inner ol {
    padding-left: 20px; }
    #overview_inner ul li, #overview_inner ol li {
      padding: 3px; }
  #overview_inner h1 {
    font-size: 160%;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #1b8dbb;
    border-top: 1px solid #1b8dbb;
    background: rgba(41, 171, 224, 0.2); }
  #overview_inner img {
    padding: 10px;
    width: 650px;
    max-width: 100%;
    display: block;
    margin: auto; }
  #overview_inner h3 {
    font-size: 140%; }
  #overview_inner h4 {
    font-size: 110%; }

.deckglhex {
  padding: 10px;
  max-width: 100%;
  margin: auto; }

#perchlorateGL {
  margin: auto;
  top: 600px; }

#overview_sidebar {
  width: 280px;
  list-style-type: none;
  text-align: left;
  padding: 20px;
  text-transform: uppercase;
  font-size: 90%;
  position: fixed;
  top: 62px;
  left: 0; }
  #overview_sidebar ul {
    padding: 0; }
    #overview_sidebar ul li {
      list-style-type: none; }
      #overview_sidebar ul li a {
        color: #333;
        text-decoration: none;
        padding: 5px 20px;
        display: block; }
      #overview_sidebar ul li a.overview_active {
        font-weight: bold; }
      #overview_sidebar ul li a:hover, #overview_sidebar ul li a:focus {
        font-weight: bold;
        background-color: transparent !important; }
  #overview_sidebar #overview_announcement {
    text-align: left;
    padding: 20px;
    font-size: 110%; }

#history p a {
  color: #29abe0; }

#history p a:hover, #history p a:focus {
  font-weight: bold;
  background-color: transparent !important; }

#howto h4 {
  padding: 10px 0;
  margin-top: 20px;
  font-weight: bold; }

#whatsnew ul {
  list-style-type: none;
  padding-left: 0px; }
  #whatsnew ul li {
    padding-left: 20px; }
  #whatsnew ul h3 {
    font-size: 120%; }

@media screen and (max-width: 1370px) {
  #overview_content {
    width: 100%; }
  #overview_inner {
    margin-left: 280px; } }
