body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff; }

body i {
  font-family: "FontAwesome" !important; }

/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  navHeight: 52;
  brushSelectionColor: #29abe0; }

/* Define an animation behavior */
@keyframes spinner {
  to {
    transform: rotate(360deg); } }

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite; }

.navbar {
  min-height: 52px;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 2000;
  position: fixed;
  width: 100%;
  padding: .5rem 1rem; }

.navbar .navbar-nav > li > a {
  color: #ccc; }

.nav-link:focus, .nav-link:hover {
  font-weight: bold;
  background-color: transparent; }

.navbar-brand:focus, .navbar-brand:hover {
  background-color: transparent; }

.navbar .navbar-nav > li > a.nav-link:focus,
.navbar .navbar-nav > li > a.nav-link:hover,
.navbar .navbar-nav > li > a.nav_active,
.navbar .navbar-nav > li > a.active {
  font-weight: bold;
  color: #29abe0; }

.navbar .navSelectedQuery {
  color: #fff; }

.navbar .navbar-text {
  /*padding:10px;*/
  padding-left: 20px;
  margin-left: 10px;
  /*margin:10px;*/
  border-left: 1px solid #666; }

.navbar-nav .nav-item .nav-link {
  text-decoration: none;
  font-weight: bold; }

.navbar-brand {
  background-color: transparent;
  color: #29abe0 !important; }

.navbar a.navbar-brand,
.navbar a.navbar-brand:hover,
.navbar a.navbar-brand:focus {
  /*color: #ddd;*/
  color: #29abe0; }

.navbar .collapse {
  height: auto; }

a {
  /*color:rgb(41, 171, 224);*/
  color: #29abe0; }

button {
  box-shadow: none !important; }

button:focus, .btn:focus, .btn-info:active:focus, .btn-info:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit !important; }

button:active:focus, .btn:active:focus {
  outline: none; }

.btn-info:active:focus, .btn-info:focus {
  background-color: #29abe0; }

/*.btn-info:hover{
  background-color:#1b8dbb;
}*/
button, .btn {
  cursor: pointer; }

.btn-info {
  color: #fff;
  background-color: #29abe0;
  border-color: #29abe0; }

.btn-info:hover {
  color: #fff;
  background-color: #1d97c8;
  border-color: #48b7e5; }

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem #48b7e5;
  box-shadow: 0 0 0 0.2rem #48b7e5; }

.btn-info.disabled, .btn-info:disabled {
  background-color: #29abe0;
  border-color: #29abe0; }

.btn.active, .btn-info.active,
.btn:active, .btn-info:active {
  background-color: #1a86b2; }

.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1a86b2;
  border-color: #5ec0e8;
  -webkit-box-shadow: 0 0 0 0.2rem #29abe0;
  box-shadow: 0 0 0 0.2rem #29abe0; }

.cf:before,
.cf:after {
  content: "";
  display: table; }

.cf:after {
  clear: both; }

#fetchingIndicator {
  position: absolute;
  left: 50%;
  width: 200px;
  text-align: center;
  padding: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #ccc;
  border-top: 0;
  margin-left: -75px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000; }

.fetchingActive {
  top: 52px;
  visibility: visible;
  transition: top .5s ease, visibility .5s ease; }

.fetchingInactive {
  top: -50px;
  visibility: hidden;
  transition: top .5s ease, visibility .5s ease; }

.fetchingSpinner {
  position: absolute;
  top: 25%;
  left: 50%;
  text-align: center;
  /*margin-left:-15px;*/
  /*background: rgba(255,255,255,0.0);*/
  z-index: 1000000; }

.queryFilters .filterList li {
  text-align: center; }

#analyte_table {
  max-width: 940px;
  margin: auto;
  padding: 20px; }
  #analyte_table #cas_id_search {
    max-width: 810px; }
  #analyte_table #cas_id_search input {
    margin: 10px 0; }
  #analyte_table .fixedDataTableLayout_main {
    outline: none; }
  #analyte_table .fixedDataTableCellLayout_main {
    border-right: none; }
  #analyte_table .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
    white-space: nowrap; }
  #analyte_table .seeAliases {
    cursor: pointer;
    display: block;
    float: right;
    color: #29abe0;
    margin-right: 40px; }
  #analyte_table .seeAliases:hover {
    color: #333;
    background-color: transparent; }
  #analyte_table .expandedContent {
    margin: 0;
    padding-left: 40px;
    list-style-type: none; }
    #analyte_table .expandedContent li {
      padding: 5px; }

.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*text-decoration: underline;*/
  display: inline;
  margin: 0;
  padding: 0; }

.button-link:hover,
.button-link:focus {
  text-decoration: none; }
