/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  barColor: #29abe0;
  barActiveColor: #f39e39;
  freqSelected: #eb744f; }

.statBar {
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  width: 350px;
  overflow-y: scroll;
  font-size: 10pt;
  background: rgba(255, 255, 255, 0.9); }

.statBar {
  text-align: left; }

.statBar h3, .statBar h4 {
  text-align: center; }

.bar text {
  fill: #999;
  font-size: 90%; }

.statBarInner {
  overflow-y: scroll; }

.toolInfo {
  padding: 5px;
  /*margin: 5px;*/
  border: 1px solid #ced7dd;
  border-radius: 5px; }

.markerRadius {
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 10px 12px;
  font-size: 11px;
  margin: 0 2px;
  border-radius: 4px;
  color: #fff;
  background: #29abe0; }

.markerRadius.active, .markerRadius:hover {
  background: #167297; }

.markerColor {
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 15px 11px;
  font-size: 11px;
  margin: 0 2px;
  border-radius: 4px;
  color: #fff;
  background: #62c896; }

.markerColor.active, .markerColor:hover {
  background: #35845d; }

.plot-toggle {
  margin: 5px 20px;
  width: calc(100% - 40px); }

.toggled-on {
  background-color: #167297 !important; }

.plot-toggle-header {
  margin: 20px 30px 10px;
  border-bottom-style: outset;
  /* text-decoration: underline; */ }

.plot-toggle-footer {
  margin: 0px 30px;
  border-bottom-style: outset; }

.locCheckbox {
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px; }
  .locCheckbox .icon-left {
    padding-right: 10px; }

#showLocations {
  margin: 5px 30px; }

#showIntensity, #showRadius {
  border-bottom: 1px solid #eee;
  width: 145px;
  float: left;
  margin-top: 5px; }

#showRadius {
  margin-left: 30px; }

.freqLine {
  fill: none;
  stroke: #e65021;
  stroke-width: 2px; }

.freqArea {
  fill: #eb744f; }

.overlay {
  fill: none;
  pointer-events: all; }

div.tooltip {
  /*position: relative;  */
  text-align: center;
  padding: 2px;
  font: 12px sans-serif;
  background: #fff;
  border: 0px;
  border-radius: 8px; }

.plotDot {
  fill: #fff;
  stroke: #e65021;
  stroke-width: 2px; }

.radioHotSpot {
  padding-left: 5px;
  margin-left: 10px; }

.disabled,
.disabled > .fa {
  opacity: 0.5;
  cursor: not-allowed; }

/* Tooltip text */
.tooltipsy > .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: relative;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s; }

/* Tooltip arrow */
.tooltipsy > .tooltiptext::after {
  content: "";
  position: relative;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipsy:hover > .tooltiptext {
  visibility: visible;
  opacity: 1; }
