.react-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000; }

.react-modal {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box; }

.active-modal {
  z-index: 10001;
  background: black; }

.new-modal {
  z-index: 10002; }

.react-modal-header {
  cursor: move; }

.modal-close-x {
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px; }

.modal-close-x:hover {
  opacity: .4; }

.react-modal-title {
  margin: 0;
  text-align: center;
  user-select: none;
  width: 100%; }

.react-modal-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: right; }

.resizeModalCorner {
  position: absolute;
  width: 20px;
  height: 20px; }
  .resizeModalCorner .fa {
    float: left;
    padding: 3px;
    transform: rotate(-45deg);
    opacity: .2; }
  .resizeModalCorner .fa:hover {
    opacity: .8; }

.resizeModalBottomRight {
  bottom: 0;
  right: 0;
  cursor: se-resize; }

.resizeModalTopRight {
  top: 0;
  right: 0;
  cursor: ne-resize; }

.resizeModalTopLeft {
  top: 0;
  left: 0;
  cursor: nw-resize; }

.resizeModalBottomLeft {
  bottom: 0;
  left: 0;
  cursor: sw-resize; }

.resizeModalTop {
  top: -10px;
  left: 20px;
  cursor: n-resize;
  width: calc(100% - 40px); }

.resizeModalBottom {
  bottom: -10px;
  left: 20px;
  cursor: s-resize;
  width: 100%;
  width: calc(100% - 40px); }

.resizeModalLeft {
  left: -10px;
  top: 20px;
  cursor: w-resize;
  height: calc(100% - 40px); }

.resizeModalRight {
  right: -10px;
  top: 20px;
  cursor: e-resize;
  height: calc(100% - 40px); }

.closeModal {
  cursor: pointer;
  background: transparent;
  opacity: .2;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  border-radius: 10px; }

.closeModal:hover {
  opacity: .8; }
  .closeModal:hover .fa-rotate-45 {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.icon-right {
  float: right; }

.dragging, .collapse.dragging {
  display: none; }
