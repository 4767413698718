/* Overview */
/* Nav Bar */
/* Map View */
/*#fc9e23;*/
/*#006400;*/
/*$selected-color:#a00;*/
/* Brush Slider */
/* Map Loc Plot */
/* Query View index.scss & Map View filterBar.scss */
:export {
  selectedColor: #a9c480; }

.locPath {
  /*stroke: steelblue;*/
  /*stroke: $site-color;*/
  stroke: #bbb;
  stroke-width: 2;
  fill: none; }

.plotDotBuffer {
  opacity: .1;
  z-index: 502; }

.plotDot {
  /*fill: $selected-color;*/
  z-index: 500; }

.xAxisYear path, .xAxisYear line {
  display: none; }

.locPlot {
  margin-bottom: 30px; }

.d3-tip {
  line-height: 1;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  z-index: 10000; }

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center; }

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0; }
