.gaugePlotContainer {
  /*font-size: 12px;
    font-family: tahoma;
    height: 140px;*/
  /*border: 1px solid #ccc;*/
  color: #555;
  display: block;
  /*padding: 10px;*/
  /*box-sizing: border-box;*/
  text-decoration: none;
  margin: 0;
  padding: 0;
  /*position: absolute;*/ }

.gaugeID:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  width: 100%; }

.closeHTML {
  float: right;
  display: inline-block;
  padding: 2px 5px;
  background: #ccc;
  cursor: pointer; }

.close-text {
  font-weight: normal;
  cursor: pointer; }

.close-text:hover {
  font-weight: bold; }

.gaugeLine {
  stroke: coral;
  fill: none; }

.gaugeCircle {
  /*fill:#3d98ff;
  opacity:.5;*/ }

.d3-tip {
  line-height: 1;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  z-index: 100000 !important; }

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center; }

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0; }
